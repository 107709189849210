.l-gallery {
  $_: &;
  &__img {
    width: 100%;
    height: 100%;

    border-radius: $radius__block;

    overflow: hidden;

    position: relative;
  }
  &__section {
    margin-top: $padding__section;
  }
  &__cover {
    @extend #{$_}__img;
    &:hover {
      &::before {
        counter-reset: num__img var(--num__img);
        content: "共" counter(num__img) "個項目";

        color: $color__font--light;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
      }
      &::after {
        @include pesudo("");
        width: 100%;
        height: 100%;
        background: $color__opacity--dark;
      }
    }
  }
  &__album {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__text {
    @include text(normal, "");
  }
  &__link {
    @include clear(btn);
    width: 100%;
    aspect-ratio: 4 / 3;
    padding: 0;
    margin-bottom: 0.5rem;

    border-radius: $radius__block;

    overflow: hidden;

    position: relative;
  }
  .c-placeholder {
    position: absolute;
  }
}

.ReactModal {
  &__Overlay {
    &--after-open {
      z-index: 1200 !important;
    }
  }
}
