.l-home {
  &__content {
    padding: $padding__section;

    background: $color--light;
    border-radius: $radius__block;
  }
}

.l-hero {
  width: 100%;
  height: $height__hero;

  position: relative;
  // &::before {
  //   @include pesudo("");
  //   width: 100%;
  //   height: 100%;

  //   background: rgba(80, 114, 130, 0.6);

  //   z-index: 1;
  // }
  @media screen and (max-width: $lg) {
    height: auto;
    aspect-ratio: 4 / 3;
  }
  &__wrapper {
    width: 100%;
    height: 100%;
  }
  &__slogan {
    width: 100%;

    position: absolute;
    top: calc(50% - $padding__section);
    transform: translateY(-50%);
    z-index: 20;
  }
  &__text {
    position: relative;
    &::after {
      @include pesudo("");
      width: $height__line;
      height: 100%;

      background: $color--light;
      left: -$padding__section;
    }
  }
  &__title {
    @include heading(h1);
    font-size: 4rem;
    line-height: 140%;
    font-weight: 300;
    color: $color__font--light;
  }
  &__subtitle {
    @include heading(h2);
    line-height: 140%;
    font-weight: 300;
    color: $color__font--light;
  }
  .swiper {
    width: 100%;
    height: 100%;
    z-index: 0;

    overflow: visible;
    &-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-pagination {
      bottom: -2rem;
      transform: translateY(-50%);
      z-index: -1;

      display: flex;
      justify-content: flex-start;
      &-bullet {
        background: $color--gray4;
        opacity: 1;
        &-active {
          background: $color--primary;
        }
      }
    }
  }
  &__caption {
    width: 100%;
    padding: 0.5rem;

    background: $color__opacity--dark;

    @include text(small, "");
    color: $color__font--light;
    text-align: start;

    position: absolute;
    bottom: 0;
    left: 0;
  }
  &__arrow {
    @include interation(btn--dark);
  }
}

.c-article-item {
  margin-bottom: 3.75rem;
  &__title {
    margin-bottom: 0.5rem;
    @include text(large, bold);

    cursor: pointer;
  }
  &__link {
    @include text(normal, "");
    color: $color--secondary;
    @include interation(link);
    @include interation(underline);

    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.c-heading {
  margin-bottom: $padding__section * 2;
  position: relative;
  z-index: 2;
  &::before {
    @include pesudo("");
    width: calc(100% + $padding__section * 2);
    height: calc(100% + $padding__section * 2);

    background: $color--light;
    border-radius: $radius__block;

    top: -$padding__section;
    left: -$padding__section;
    z-index: -1;
  }
  &__title {
    margin-bottom: 1rem;

    @include heading(h4);
    color: $color__font--primary;
  }
  &__content {
    @include text(normal, "");
    line-height: 180%;
    cursor: text;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    overflow: hidden;
  }
  &__item {
    @include text(normal, "");
  }
}
