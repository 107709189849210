.l-contact {
  &__img {
    width: 100%;

    border-radius: $radius__block;
    overflow: hidden;

    position: relative;
  }
}
