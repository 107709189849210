body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
  line-height: 200%;
  color: $color__font--text;

  overflow: overlay;

  @include scrollbar("");
}

h1 {
  @include heading(h1);
}

h2 {
  @include heading(h2);
}

h3 {
  @include heading(h3);
}

h4 {
  @include heading(h4);
}

h5 {
  @include heading(h5);
}

h6 {
  @include heading(h6);
}

p {
  margin-bottom: 0.5rem;
  @include text(normal, "");
  cursor: text;
}

a {
  color: inherit;
  @include cursor(pointer);
  &:hover {
    color: inherit;
  }
}

.col-xxxl {
  max-width: 100rem;
}

.e-img {
  width: 100%;
  height: 100%;
  &--contain {
    object-fit: contain;
  }
  &--cover {
    object-fit: cover;
  }
  &--43 {
    aspect-ratio: 4 / 3;
  }
}

.e-btn {
  cursor: pointer !important;
  &--text {
    width: max-content;
    padding: 0 1rem;
    height: 2.75rem;

    @include clear(btn);
    border-radius: $radius__btn;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  &--w100 {
    @extend .e-btn--text;
    width: 100%;
  }
  &--content {
    @extend .e-btn--text;
    width: max-content;
    padding: 0 2rem;
  }
  &--icon {
    width: 2.75rem;
    height: 2.75rem;

    @include clear(btn);
    border-radius: $radius__btn;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  &--tab {
    height: 2.75rem;
    padding: 0 1rem;

    @include clear(btn);
    @include text(normal, 600);
    color: $color__font--text;
  }
  &--primary {
    background: $color--primary;
    color: $color__font--light;
    @include interation(btn);
  }
  &--outline {
    border: 0.0625rem solid $color--secondary;
    color: $color__font--secondary;
    @include text(normal, "");
    &:hover {
      background: $color--secondary;
      color: $color__font--light;
    }
  }
  &--opacity {
    border-radius: $radius__btn;
    @include interation(btn--opacity);
  }
  &--muted {
    background: $color--gray5;
    @include interation(btn);
  }
  // &--link {
  //   padding-right: 3rem;
  //   position: relative;
  //   &::after {
  //     @include pesudo("\f138");
  //     font-size: 1.5rem;
  //     color: $color--light;

  //     left: auto;
  //     right: 0;
  //   }
  // }
}

.e-link {
  color: $color--accent1;
  @include interation(link);
  @include cursor(pointer);
}

.c-form {
  &__set {
    margin-bottom: 1rem;
    &:last-of-type {
      margin-bottom: 2rem;
    }
    &--row {
      display: flex;
      align-items: center;
    }
  }
  &__label {
    margin-bottom: 0;
    @include text(small, 400);
    color: $color__font--muted;
  }
  &__input {
    height: $height__input;

    background-color: $color--light;
    border-radius: $radius__input;
    border: $border__tab;

    &::placeholder {
      color: $color--gray4;
    }
    &:focus,
    &:active {
      box-shadow: none;
      border: $border__input--active;
    }
    &--textarea {
      height: auto;
    }
  }
  &__link {
    margin-top: 0.5rem;
    @include text(small, 400);

    display: flex;
    justify-content: flex-end;

    @include cursor(pointer);
  }
  &__wrapper {
    position: relative;
  }
  &__icon-btn {
    @include clear(btn);
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
  }
  &__icon {
    color: $color__font--primary;
    &:hover {
      color: $color--hover;
    }
  }
  &__hint {
    color: $color--error;
  }
  &__actions {
    width: 100%;
    margin-top: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__action {
    @include clear(btn);
    height: $height__input;
    padding: 0 1.5rem;

    border-radius: $radius__btn;

    @include text(normal, "");
    white-space: nowrap;

    display: flex;
    align-items: center;
    justify-content: center;
    @include interation(btn);
    &.action {
      @extend .e-btn--primary;
    }
    &.muted {
      @extend .e-btn--muted;
    }
    &:first-of-type {
      margin-right: 1rem;
    }
  }
  &__text {
    height: $height__input;
    margin: 0 1rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.e-hr {
  width: 100%;
  margin: 0;
  height: $height__line;
  background: $color--light;
}

.e-icon {
  font-size: 1rem;
  &--primary {
    color: $color--primary;
  }
  &--right {
    margin-right: 0.5rem;
  }
  &--secondary {
    color: $color--secondary;
  }
  &--muted {
    color: $color__font--muted;
  }
  &--light {
    color: $color__font--light;
  }
}

.c-title {
  $_: &;
  width: 100%;
  height: $height__title;
  margin-bottom: $margin__title;

  position: relative;

  display: flex;
  align-items: center;
  @media screen and (max-width: $lg) {
    height: auto;
  }
  &__text {
    margin-bottom: 0.5rem;

    @include heading(h4);
    color: $color--primary;
  }
  &::after {
    @include pesudo("");
    width: 100%;
    height: $height__line;

    background: $color__opacity--primary;
    border-radius: $radius--infinity;

    top: auto;
    bottom: 0;
  }
  &--sidebar {
    margin-bottom: 1rem;
    #{$_}__text {
      margin-bottom: 0;
      @include text(medium, 500);
    }
  }
  &--popover {
    margin-bottom: 1rem;
    #{$_}__text {
      margin-bottom: 0;
      @include text(medium, 500);
      color: $color__font--secondary;
      @media screen and (max-width: $lg) {
        @include text(normal, 500);
      }
    }
    &::after {
      background: $color__opacity--secondary;
    }
  }
}

.c-list {
  &__item {
    margin-bottom: 0.5rem;
    @include text(normal, "");
    &:last-of-type {
      margin-bottom: 0;
    }
    &--dot {
      padding-left: 1.5rem;

      position: relative;
      &::after {
        @include pesudo("");
        @include square(0.25rem, 50%);
        background: $color--primary;

        top: 50%;
        left: 0.5rem;
        transform: translateY(-50%);
      }
    }
  }
  &__title {
    width: 5rem;
    @include text(normal, bold);
    display: inline-block;
  }
}

.c-article {
  &__title {
    width: max-content;
    height: 4rem;
    padding: 0.5rem 1.5rem;
    margin-bottom: 1.5rem;

    background: $color--plain;
    border-radius: $radius--infinity;

    @include heading(h5);
    color: $color__font--primary;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__paragraph {
    margin-bottom: 2rem;
    text-indent: 2rem;
  }
  &__img {
    width: 60%;
    margin: 0 auto 1rem auto;
    @media screen and (max-width: $lg) {
      width: 100%;
    }
  }
  &__caption {
    margin: 1rem 0;
    @include text(normal, "");
    text-align: center;
  }
  &__section {
    margin-bottom: 4rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__subsection {
    margin-bottom: 2rem;
  }
  &__text {
    margin-bottom: 0.5rem;

    @include text(normal, 400);
  }
  &__list {
    margin-bottom: 2rem;
  }
}

.c-popover {
  width: max-content;
  min-width: 15rem;
  max-width: none;
  padding: 1rem;
  margin-bottom: 0;

  border-radius: $radius__popover;
  border: none;
  .popover-arrow {
    width: 1rem;
    height: 1rem;

    right: -1rem;
    bottom: -1rem;
    &::before {
      display: none;
    }
    &::after {
      border-width: 0.375rem 0 0.375rem 0.75rem;

      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
  &__title {
    @include text(medium, 500);
    color: $color__font--secondary;
  }
  &__body {
    padding: 0;
  }
  &__item {
    @include text(normal, "");
    color: $color__font--subtext;
    line-height: 160%;

    display: grid;
    grid-template-columns: 8rem 1fr;
  }
  &__col {
    @include text(normal, "");

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &--title {
      color: $color__font--secondary;
    }
    @media screen and (max-width: $lg) {
      @include text(small, "");
      &--strong {
        padding-right: 1rem;
        color: $color__font--primary;
      }
    }
  }
  &__seg {
    margin-left: 0.5rem;
    @include text(small, "");
    color: $color__font--muted;
  }
  &__action {
    margin-top: 1rem;
    color: $color__font--secondary !important;
    &:hover {
      color: $color__font--light !important;
    }
  }
  .leaflet-popup-content {
    margin: $padding__popover;
  }
}

.c-subtitle {
  width: max-content;
  max-width: 100%;
  height: $height__subtitle;
  margin-bottom: $margin__subtitle;
  padding: 0.5rem 1rem;

  border-radius: $radius--infinity;

  background: $color--light;
  box-shadow: $shadow__deco;

  display: flex;
  align-items: center;

  position: relative;
  &--left {
    padding-left: 0.5rem;
  }
  &__icon {
    font-size: 2rem;
  }
  &__text {
    @include heading(h6);
    color: $color--primary;
  }
  &__deco {
    $size: 3rem;
    width: $size;
    height: $size;
    padding: 0.75rem;
    margin-right: 0.5rem;

    background: $color--primary;
    border-radius: $radius--infinity;
  }
}

.c-breadcrumb {
  margin-bottom: 1rem;
  &__seg {
    margin-right: 1.25rem;
    @include text(small, "");
    color: $color__font--muted;

    position: relative;
    &::after {
      @include pesudo("\f0da");
      color: $color__opacity--secondary;

      top: 50%;
      left: calc(100% + 0.25rem);
      transform: translateY(-50%);
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
  }
  &__link {
    color: $color__font--primary;
    @include interation(link);
  }
}

.c-page {
  min-height: 100vh;
  padding: $height__header--top 0;
  background: $color--plain;
  &__container {
    width: 100%;
    margin-bottom: $padding__section;
    &:last-of-type {
      margin-bottom: 0;
    }
    &--light {
      background: $color--light;
      padding: $padding__section;
      border-radius: $radius__block;
    }
  }
  &--bg {
    background: $color--light;
    border-radius: $radius__block;
  }
  &__section {
    margin-bottom: 1.5rem;
  }
  @media screen and (max-width: $lg) {
    padding: 2rem 0;
  }
}

.c-card {
  $_: &;
  &--list {
    width: 100%;
    height: 100%;
    padding: 1rem;

    background: $color--light;
    border-radius: $radius__card;

    display: flex;
    @include cursor(pointer);
    &:hover {
      background: $color--gray7;
      #{$_}__title {
        color: $color__font--primary;
      }
    }
    #{$_}__img {
      $size: 4rem;
      width: $size;
      min-width: $size;
      height: $size;
      margin-right: 1rem;

      border-radius: $radius__card;

      overflow: hidden;
    }
    #{$_}__title {
      @include heading(h6);
      @include text-truncate(2);
      @include cursor(pointer);
    }
    #{$_}__subtitle {
      color: $color__font--muted;
    }
  }
  &--item {
    height: 100%;
    padding: 0.5rem;

    background: $color--light;
    border-radius: $radius__block;
    box-shadow: $shadow__deco;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    #{$_}__title {
      margin-bottom: 0.25rem;

      @include text(small, 400);
      color: $color__font--subtext;
    }
    #{$_}__strong {
      @include heading(h3);
      font-weight: 900;
      line-height: 120%;
      color: $color--primary;
      text-align: end;
    }
    #{$_}__text {
      @include text(extra-small, 400);
      color: $color__font--muted;
      line-height: 120%;

      display: block;
    }
    #{$_}__muted {
      @include text(normal, 400);
      color: $color__font--muted;
      text-align: end;

      display: block;
    }
  }
}

.c-reference {
  margin-bottom: 1.5rem;
  @include text(small, "");
  color: $color__font--subtext;
  line-height: 150%;
  letter-spacing: 0;

  cursor: text;
  &:last-of-type {
    margin-bottom: 0;
  }
  &__seg {
    margin-right: 0.5rem;
  }
  &__name {
    margin-right: 0.5rem;
  }
  &__ref {
    font-style: italic;
  }
}

.c-tabs {
  display: flex;
  align-items: center;
  &__tab {
    @include clear(btn);
    padding: $padding__tab;
    margin-right: 0.5rem;

    background: $color--gray6;
    border-radius: $radius__btn;

    @include text(normal, "");
    color: $color__font--subtext;

    @include interation(btn--opacity);
    @include cursor(pointer);
    &:last-of-type {
      margin-right: 0;
    }
    &.active {
      background: $color--primary;
      color: $color__font--light;
      @include cursor(default);
    }
  }
}

.c-select {
  &__label {
    @include text(small, 400);
    color: $color__font--text;
  }
  &__input {
    @extend .c-form__input;
    padding: $padding__tab;
    padding-right: 2rem;
    margin-right: 1rem;
    @include text(normal, "");

    border-radius: $radius__btn;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%233b9750%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");

    @include cursor(pointer);
    &:focus,
    &:active {
      box-shadow: none;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
  @media screen and (max-width: $lg) {
    width: 100%;
  }
}

.c-map {
  $_: &;
  width: 100%;
  min-height: calc(100vh - $height__header);
  height: 100%;
  .leaflet-div-icon {
    @include clear(btn);
    &:hover,
    &:focus,
    &:active {
      z-index: 999 !important;
    }
    &:has(> .active) {
      z-index: 999 !important;
    }
  }
  &__marker {
    @include square(2rem, 0);
    padding: 0.3rem;

    border-radius: $radius--infinity;
    background: $color--primary;
    box-shadow: $shadow__tag;

    position: relative;
    left: 50%;
    top: 100%;
    transform: translate(-50%);

    transition: $transition;
    &:hover {
      position: relative;
      background: $color--secondary;
      box-shadow: $shadow__tag;
    }
    &.active {
      background: $color--secondary;
    }
  }
}

.c-placeholder {
  $_: &;
  width: 100%;
  height: 100%;

  background: $color--gray6;
  border-radius: $radius__block;
  // -webkit-mask-image: -webkit-radial-gradient(white, black);

  overflow: hidden;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  &::after {
    @include pesudo("");
    width: 100%;
    height: 100%;
    animation: slideRight 1.6s linear 0.5s infinite;
    -webkit-animation: slideRight 1.6s linear 0.5s infinite;
    background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent);
    transform: translateX(-100%);

    top: 0;
    left: 0;
  }

  &--list {
    height: 4rem !important;
    margin-bottom: 0.5rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &--block-list {
    height: 10rem;
    margin-bottom: 2rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &--graph {
    #{$_}__icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    #{$_}__img {
      @include square(3rem, 0);
      margin-bottom: 0.25rem;
    }
    #{$_}__text {
      @include text(small, 400);
      color: $color--primary;
    }
  }
  &--block {
    aspect-ratio: 1 / 1;
    background: transparent;
    &::after {
      display: none;
    }
    #{$_}__icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    #{$_}__img {
      @include square(3rem, 0);
      margin-bottom: 0.25rem;
    }
    #{$_}__text {
      @include text(small, 400);
      color: $color--primary;
    }
  }
  &--full-page {
    position: absolute;
    #{$_}__img {
      @include square(3rem, 0);
    }
    #{$_}__text {
      display: none;
    }
  }
  &--text {
    height: 2.75rem;

    @include text(normal, 400);
    color: $color--primary;
  }
}

.c-accordion {
  $_: &;
  border: none;
  &__body {
    padding: 0 0 0.5rem 0;
  }
  &__item {
    padding: $padding__dropdown;

    @include text(normal, "");
    @include cursor(pointer);
    &.active {
      color: $color__font--primary;
      font-weight: 500;
    }
  }
  &--header {
    #{$_}__toggle {
      padding: $padding__action;

      border-bottom: $border__offcanvas-item;
      border-radius: $radius__accordion !important;

      @include text(normal, 500);

      display: flex;
      align-items: center;
      &::after {
        @include arrow;
        top: 50%;
        left: auto;
        right: 2rem;
        transform: translateY(-50%);
      }
      &:not(.collapsed) {
        box-shadow: none;
        border-bottom: $border__offcanvas-item;
        background: $color--light;

        color: $color--primary;
        &::after {
          background-image: none;

          color: $color--primary;

          transform: translateY(-50%) rotate(90deg);
        }
      }
      &:hover {
        &::after {
          color: $color--light;
          @media screen and (max-width: $spec__header--m) {
            color: $color--primary;
          }
        }
      }
      &:focus {
        border-bottom: $border__offcanvas-item;
        box-shadow: none;
        &::after {
          color: $color--primary;
        }
      }
    }
    #{$_}__item {
      padding: 0;

      border: none;
      box-shadow: none;
      border-radius: $radius__accordion !important;
    }
  }
}

.c-checkbox {
  $_: &;
  &__set {
    margin-bottom: 0.5rem;

    display: flex;
    align-items: center;

    @include cursor(pointer);
  }
  &__input {
    @include clear(input);
    @include square(1rem, $radius__input);
    max-width: 1rem;
    margin: 0 0.5rem 0 0;
    padding: 0;

    border: 0.0625rem solid $color--gray5;
    background: $color--light;

    &:focus {
      border: 0.0625rem solid $color--gray5;
    }
    &:checked {
      background: $color--primary;
      border: 0.0625rem solid $color--primary;
    }
  }
  &__label {
    margin-bottom: 0;
    @include text(normal, "");
    color: $color__font--text;
  }
  &__text {
    margin-left: 0.25rem;
  }
  &__subtext {
    @include text(small, "");
    color: $color__font--muted;
  }
  &--col {
    flex-direction: column;
    #{$_}__set {
      margin: 0 1rem 0.5rem 0;
    }
    #{$_}__input {
      padding: 0;
    }
    #{$_}__label {
      margin-bottom: 0;
    }
    #{$_}__row {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    #{$_}__title {
      @extend .c-form__label;
    }
  }
  &--check {
    #{$_}__input {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E");
    }
  }
}

.c-btns {
  margin-top: 1.5rem;

  display: flex;
  align-items: center;
  &__btn {
    &:first-child {
      margin: 0 0.5rem 0 0;
    }
    @media screen and (max-width: $sm) {
      width: 100%;
      &:first-child {
        margin: 0 0 0.5rem 0;
      }
    }
  }
}

.c-table {
  $_: &;
  &__row {
    display: grid;
    grid-template-columns: 1fr 3fr 2fr;
    &:last-of-type {
      #{$_}__col {
        &:first-of-type {
          border-radius: 0 0 0 $radius__block;
        }
        &:last-of-type {
          border-radius: 0 0 $radius__block 0;
        }
      }
    }
  }
  &__col {
    padding: $padding__tab;
    background: $color__bg--primary;
    @include text(normal, "");
    color: $color__font--text;
    &--head {
      background: $color--primary;
      color: $color__font--light;
      white-space: nowrap;
      &:first-of-type {
        border-radius: $radius__block 0 0 0;
      }
      &:last-of-type {
        border-radius: 0 $radius__block 0 0;
      }
    }
  }
}

.c-alert {
  &__container {
    padding-right: $margin__alert;
  }
  &__overlay {
    background: $color__opacity--dark !important;
  }
  &__modal {
    background-color: $color--light !important;
    border: none !important;
    border-radius: $radius__block !important;
  }
  &__title {
    color: $color__font--text !important;
    @include text(normal, 400);
  }
  &__text {
    color: $color__font--text !important;
    @include text(small, 400);
  }
  &__toast {
    border: none !important;
    border-radius: $radius__block !important;
    box-shadow: $shadow__dropdown !important;
    overflow: auto;

    top: calc($height__header + 1rem);
    &.error {
      background-color: $color__bg--error !important;
    }
    &.success {
      background-color: $color__bg--secondary !important;
    }
  }
  &__subtitle {
    color: $color__font--text !important;
    @include text(small, 400);
    font-weight: 400 !important;
  }
}

.e-hash {
  position: relative;

  top: calc(($height__header + 1rem) * -1);
  @media screen and (max-width: $spec__header--m) {
    top: calc(($height__header--m + 1rem) * -1);
  }
}

.c-scrolltop {
  @include clear(btn);
  @include square(3rem, 50%);
  background: $color--primary;
  box-shadow: $shadow__dropdown;

  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;

  &:focus,
  &:active {
    box-shadow: $shadow__dropdown;
  }
}
