.l-people {
  width: 100%;
  height: max-content;
  &__card {
    padding: $padding__section;
    margin-bottom: 2rem;

    background: $color--gray6;
    border-radius: $radius__card;
    display: flex;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__img {
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    overflow: hidden;
    @media screen and (max-width: $md) {
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__table {
    width: 100%;
  }
  &__row {
    display: grid;
    grid-template-columns: 1fr 3fr;
    @media screen and (min-width: $xl) {
      grid-template-columns: 1fr 6fr;
    }
  }
  &__col {
    display: flex;
    align-items: flex-start;

    @include text(normal, 400);
    &--head {
      @include text(normal, 500);
      color: $color__font--primary;
    }
  }
}

.l-news-and-events {
}
