:root {
  --color--primary: rgba(59, 151, 80, 1); //#3b9750
  --color--secondary: rgba(70, 126, 191, 1); //#467ebf
  --color--accent1: rgba(0, 72, 124, 1); //#00487c
  --color--accent2: rgba(52, 16, 115, 1); //#341073
  --color--error: rgba(207, 105, 79, 1); //#cf694f

  --color--light: #ffffff;
  --color--plain: #ebf5ed;
  --color--common: #d8eadc;

  --color--gray1: rgba(51, 51, 51, 1); //#333333
  --color--gray2: rgba(80, 80, 80, 1); //#505050
  --color--gray3: rgba(130, 130, 130, 1); //#828282
  --color--gray4: rgba(189, 189, 189, 1); //#bdbdbd
  --color--gray5: rgba(224, 224, 224, 1); //#e0e0e0
  --color--gray6: rgba(242, 242, 242, 1); //#f2f2f2
  --color--gray7: rgba(250, 250, 250, 1); //#fafafa

  --color__opacity--primary: rgba(59, 151, 80, 0.3);
  --color__opacity--secondary: rgba(97, 139, 194, 0.5);
  --color__opacity--hover: rgba(55, 145, 53, 0.2);
  --color__opacity--dark: rgba(0, 0, 0, 0.2);
  --color__opacity--light: rgba(255, 255, 255, 0.2);
  --color__opacity--error: rgb(207, 105, 79, 0.2);

  --color__shadow: rgba(0, 0, 0, 0.25);
  --color__shadow--dropdown: rgba(0, 0, 0, 0.3);

  --color__font--text: var(--color--gray1);
  --color__font--subtext: var(--color--gray2);
  --color__font--muted: var(--color--gray3);
  --color__font--light: var(--color--light);
  --color__font--primary: #2f7940;
  --color__font--secondary: #2966ac;

  --color__dropdown: var(--color--light);
  --color__dropdown--hover: #ddede0;
  --color__nav: #3b9750;
  --color__nav--hover: #4fa161;

  --color__bg--primary: var(--color--plain);
  --color__bg--secondary: #ecf2f9;
  --color__bg--error: #faf0ed;

  --color--hover: var(--color--secondary);
  --color--active: var(--color__font--secondary);

  --num__nav: 0;
  --num__img: 0;
}
