.l-header {
  $_: &;
  $height__content: 3rem;
  height: $height__header--top;
  padding: 0;
  background: $color--plain;

  position: sticky;
  top: 0;
  z-index: 1100;
  &__top {
    width: 100%;
    height: $height__header--top;
    padding: $padding__header;
    @media screen and (max-width: $spec__header--m) {
      background: $color--primary;
      box-shadow: $shadow__nav;
    }
  }
  &__logo {
    height: $height__content;
    @include cursor(pointer);
  }
  &__img {
    height: $height__header--top - $padding__header * 2;
    margin-right: 1rem;
  }
  &__item {
    margin-right: 0.5rem;
    &:last-of-type {
      margin-right: 0;
    }
  }
  &__tab {
    background: $color--light;
    border-radius: $radius__tab--header;
    border-bottom: none;
    box-shadow: $shadow__tab;

    @include interation(link);
  }
  &__dropdown {
    background: $color--light;
    border-radius: $radius__dropdown--header;
    border: $border__tab;
    border-top: none;
  }
  &__link {
    color: $color--primary;

    transition: $transition;
    @include interation(dropdown-item--light);
  }
  &__text {
    @include text(normal, 500);
    line-height: 140%;
    color: $color__font--primary;
    @media screen and (max-width: $spec__header--m) {
      color: $color__font--light;
    }
    @media screen and (max-width: $md) {
      @include text(small, 500);
    }
    @media screen and (max-width: $sm) {
      display: none;
    }
  }
  &__search {
    width: 20rem;
  }
  &__list {
    &--btns {
      display: flex;
      align-items: center;
    }
    &--mobile {
      #{$_}__item {
        padding: $padding__action !important;
        margin: 0 !important;

        border-bottom: $border__offcanvas-item;
        border-radius: $radius__accordion !important;

        @include text(normal, 500);

        display: flex;
        align-items: center;
        &.active {
          @extend .c-sidebar__tab, .active;
          &::after {
            right: 0.75rem !important;
          }
        }
      }
      #{$_}__link {
        width: 100%;
        height: 100%;

        display: block;

        color: $color__font--text;
      }
    }
  }
  &__item {
    display: flex;
    align-items: center;
  }
  &__btn {
    @include clear(btn);
    height: $height__content;
    padding: 0 1rem;

    background: $color--light;
    border-radius: $radius__btn;

    @include text(small, 400);
    color: $color__font--subtext;

    @include cursor(pointer);

    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: $color--primary;
      color: $color__font--light;
      .e-icon {
        color: $color__font--light;
      }
      @media screen and (max-width: $spec__header--m) {
        background: $color--light;
        color: $color__font--subtext;
        .e-icon {
          color: $color--primary;
        }
      }
    }
  }
  &__toggle {
    @include clear(btn);
    height: $height__content;
    padding: 0 1rem;

    background: $color--light;
    border-radius: $radius__btn;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__body {
    padding-top: $height__header--m;
  }
  &__offcanvas {
    width: 60%;
    height: 100%;
  }
  &__backdrop {
    background: $color__opacity--dark;
  }
}

.l-nav {
  width: 100%;
  height: $height__nav;

  background: $color__nav;
  box-shadow: $shadow__nav;

  position: sticky;
  top: 0;
  z-index: 1100;

  display: flex;
  align-items: center;
  justify-content: center;
  &__list {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
  }
  &__toggle {
    width: 100%;
    padding: 0.75rem 0.375rem;
    margin: 0;

    @include clear(btn);
    border-radius: $radius__dropdown;

    @include text(small, "");
    color: $color__font--subtext;
    white-space: normal;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
    @include cursor(pointer);
    &::after {
      display: none !important;
    }
    &:hover {
      color: $color--primary;
      background-color: $color--plain;
    }
    &--navbar {
      height: 100%;

      @include text(normal, "");
      color: $color--light;

      justify-content: center;
      &.active {
        background: $color--light;
        box-shadow: $shadow__dropdown;
        border-radius: $radius__dropdown $radius__dropdown 0 0;

        color: $color--primary;

        position: relative;
        &::before {
          @include pesudo("");
          width: calc(100% - 2rem);
          height: $height__line;

          background: $color__opacity--primary;

          top: auto;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
  &__item {
    width: calc(100% / $num__nav);
    height: 3rem;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    @include cursor(pointer);
  }
  &__dropdown {
    min-width: 100%;
    padding: $padding__dropdown;

    border: none;
    background: transparent;

    &::after {
      @include pesudo("");
      width: 100%;
      height: 100%;

      background: $color__dropdown;
      box-shadow: $shadow__dropdown;

      z-index: -1;

      opacity: 0;
      transition: $transition;
    }
    &.show {
      &::after {
        opacity: 1;
      }
    }
    &--down {
      &.show {
        margin-top: 0;
        left: 50% !important;
        transform: translate3d(-50%, 0, 0) !important;
        &::after {
          border-radius: 0 0 $radius__dropdown $radius__dropdown;
        }
      }
    }
    &--end {
      &.show {
        display: none;
        top: 0;
        transform: translate3d(100%, 0, 0) !important;
        &::after {
          border-radius: $radius__dropdown;
        }
      }
    }
  }
  &__link {
    width: 100%;
    padding: 0.75rem 1rem;

    @include text(small, "");
    color: $color__font--subtext;
    text-align: center;

    border-radius: $radius__dropdown;

    display: flex;
    justify-content: center;
    align-items: center;

    @include cursor(pointer);
    &:hover {
      background: $color--plain;

      color: $color--primary;
    }
    &--navbar {
      height: 100%;
      padding: 0 1rem;

      @include text(normal, "");
      color: $color--light;

      justify-content: center;
      &:hover {
        background: $color--light;

        color: $color--primary;
      }
    }
  }
}

.l-footer {
  width: 100%;
  height: max-content;
  padding: 2.5rem 0;
  background: $color--common;
  color: $color--light;
  &__sponsor {
    @include cursor(pointer);
  }
  &__title {
    margin-bottom: 0;
    @include text(small, 500);
    color: $color__font--primary;

    @include text-truncate(1);
  }
  &__img {
    $width: 3rem;
    width: $width;
    min-width: $width;
    aspect-ratio: 1 / 1;
    margin-right: 0.5rem;
  }
  &__hr {
    background: $color--primary;
  }
  &__text {
    @include text(small, "");
    color: $color__font--primary;
    text-align: center;
  }
  &__subtext {
    margin-bottom: 0;
    @include text("extra-small", "");
    color: $color__font--muted;
  }
  &__links {
    @include text(extra-small, "");
    color: $color__font--primary;

    display: flex;
    align-items: center;
  }
  &__link {
    margin: 0 0.5rem;
  }
}

.c-sidebar {
  $_: &;
  width: 100%;
  height: 100%;
  padding: $padding__section;

  background: $color--light;
  border-radius: $radius__block;

  @include text(normal, "");
  color: $color__font--subtext;
  &__deco {
    @extend .c-subtitle__deco;

    $size: 2.5rem;
    width: $size;
    height: $size;
    padding: 0.6rem;
    margin-right: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;

    color: $color__font--light;
  }
  &__tab {
    @include clear(btn);
    width: 100%;
    padding: $padding__icon-tab;
    margin-bottom: 0.5rem;

    border-radius: $radius__btn;

    color: $color__font--text;

    display: flex;
    align-items: center;
    @include interation(btn--opacity);
    &.active {
      padding: 0.5rem 2.5rem 0.5rem 0.5rem;
      background: $color--plain;
      &:focus,
      &:active {
        color: $color__font--text;
      }
      position: relative;
      &::after {
        @include square(1.5rem, 0);
        @include pesudo("\f138");
        font-size: 1.5rem;
        color: $color--primary;

        left: auto;
        right: 0;
      }
    }
    &--accordion {
      position: relative;
      &::after {
        @include pesudo("\f107");
        @include square(1.5rem, 0);
        font-size: 1.25rem;
        color: $color--primary;

        left: auto;
        right: 0;
      }
      &:not(.collapsed) {
        background: $color__opacity--primary;

        color: $color__font--text;
      }
    }
  }
  &__content {
    position: sticky;
    top: calc($height__nav + 5.5rem);

    overflow-y: auto;
    @include scrollbar(hidden);
    @media screen and (max-width: $spec__header--m) {
      height: max-content;
    }
  }
  &__link {
    @include text(small, 400);
    color: $color__font--muted;

    @include cursor(pointer);
  }
}

.c-filter {
  $_: &;

  &__btn {
    width: 100%;
    padding: $padding__tab;
    border-radius: $radius__btn;

    background: $color--gray6;
    border: none;

    @include interation(btn--opacity);
    @include cursor(pointer);
    &.active {
      background: $color--primary;
      #{$_}__subtitle,
      #{$_}__subtext {
        color: $color--light;
      }
    }
  }
  &__subtitle {
    @include text(normal, "");
    color: $color--primary;
    text-align: center;
  }
  &__subtext {
    margin: 0 0.5rem;
    @include text(normal, "");
    color: $color__font--subtext;
  }
  &__select {
    width: 100%;
    @include text(small, "");
    @include cursor(pointer);
  }
}

.c-pagination {
  $_: ".page-link";
  margin-top: $margin__section;
  #{$_} {
    @include square(2rem, 50%);

    background: $color--light;
    box-shadow: $shadow__tab;
    border: 0.0625rem solid transparent;

    @include text(normal, "");
    color: $color--primary;

    display: flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &:focus,
    &:active {
      background: $color--gray6;

      @include cursor(pointer);
    }
  }
  &__btn {
    margin-right: 0.5rem;
    &:first-of-type {
      #{$_} {
        border-radius: 50%;
      }
    }
    &:last-of-type {
      margin-right: 0;
      #{$_} {
        border-radius: 50%;
      }
    }
    &.active {
      #{$_} {
        background: $color--primary;
        border: 0.0625rem solid transparent;

        color: $color__font--light;
      }
    }
  }
}
