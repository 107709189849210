$color--primary: var(--color--primary);
$color--secondary: var(--color--secondary);
$color--hover: var(--color--hover);
$color--active: var(--color--active);
$color--accent1: var(--color--accent1);
$color--accent2: var(--color--accent2);
$color--error: var(--color--error);

$color--light: var(--color--light);
$color--plain: var(--color--plain);
$color--common: var(--color--common);

$color--gray1: var(--color--gray1);
$color--gray2: var(--color--gray2);
$color--gray3: var(--color--gray3);
$color--gray4: var(--color--gray4);
$color--gray5: var(--color--gray5);
$color--gray6: var(--color--gray6);
$color--gray7: var(--color--gray7);

$color__font--text: var(--color__font--text);
$color__font--subtext: var(--color__font--subtext);
$color__font--muted: var(--color__font--muted);
$color__font--light: var(--color__font--light);
$color__font--primary: var(--color__font--primary);
$color__font--secondary: var(--color__font--secondary);
$color__font--accent3: var(--color__font--accent3);

$color__opacity--primary: var(--color__opacity--primary);
$color__opacity--secondary: var(--color__opacity--secondary);
$color__opacity--hover: var(--color__opacity--hover);
$color__opacity--dark: var(--color__opacity--dark);
$color__opacity--light: var(--color__opacity--light);
$color__opacity--error: var(--color__opacity--error);

$color__shadow: var(--color__shadow);
$color__shadow--dropdown: var(--color__shadow--dropdown);

$color__dropdown: var(--color__dropdown);
$color__dropdown--hover: var(--color__dropdown--hover);
$color__nav: var(--color__nav);
$color__nav--hover: var(--color__nav--hover);

$color__bg--primary: var(--color__bg--primary);
$color__bg--secondary: var(--color__bg--secondary);
$color__bg--error: var(--color__bg--error);

$height__line: 0.0625rem;
$height__nav: 3rem;
$height__header: 8rem;
$height__header--top: 5rem;
$height__header--m: 5rem;
$height__title: 3rem;
$height__subtitle: 4rem;
$height__hero: 70vh;
$height__input: 2.75rem;

$padding__dropdown: 0.5rem;
$padding__section: 2rem;
$padding__tab: 0.5rem 1rem;
$padding__tag: 0.25rem 1rem;
$padding__icon-tab: 0.5rem;
$padding__popover: 1rem;
$padding__header: 1rem;
$padding__block: 1rem;
$padding__action: 0.75rem 1rem;

$margin__title: 1.5rem;
$margin__subtitle: 1rem;
$margin__alert: 1rem;
$margin__section: 2rem;
$margin__block: 1rem;

$radius__tab: 0.5rem;
$radius__tag: 0.25rem;
$radius__tab--header: 0.25rem 0.25rem 0 0;
$radius__input: 0.25rem;
$radius__btn: 0.5rem;
$radius__dropdown: 0.5rem;
$radius__dropdown--header: 0 0 0.25rem 0.25rem;
$radius__card: 0.5rem;
$radius__block: 0.5rem;
$radius__popover: 0.5rem;
$radius--infinity: 100rem;
$radius__accordion: 0;

$border__tab: 0.0625rem solid $color--gray5;
$border__input: 0.0625rem solid $color--gray5;
$border__input--active: 0.0625rem solid $color--primary;
$border__section: 0.0625rem solid $color--primary;
$border__nav: 0.0625rem solid $color__opacity--light;
$border__offcanvas-item: 0.0625rem solid $color--gray6;

$shadow__tab: 0 0 0.2rem 0 $color__shadow;
$shadow__nav: 0 0.5rem 0.5rem 0 $color__shadow;
$shadow__dropdown: 0 0.25rem 0.5rem 0 $color__shadow;
$shadow__card: 0 0 0.5rem 0 $color__shadow;
$shadow__tag: 0.25rem 0.25rem 0.25rem 0 $color__shadow;
$shadow__tag--active: 0.25rem 0.25rem 1rem 0 $color__shadow--dropdown;
$shadow__deco: 0rem 0rem 0.25rem 0 $color__shadow;
$shadow__focus: 0 0 0 0.5rem $color__opacity--primary;

$transition: 0.2s ease-in-out;

/* rwd spec */
$sm: 575px;
$md: 767px;
$lg: 991px;
$xl: 1199px;
$xxl: 1399px;

$spec__header--m: $xl;

$num__nav: var(--num__nav);

$icon__right: "\f054";
