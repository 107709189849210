/* google fonts */
// Noto Sans TC
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap");

// Fontawesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";

@mixin heading($size) {
  font-family: Arial, "Noto Sans TC", sans-serif;
  font-style: normal;
  line-height: 160%;
  letter-spacing: 0;

  cursor: default;

  @if $size == h1 {
    font-size: 3.25rem;
    font-weight: 500;
  } @else if $size == h2 {
    font-size: 2.5rem;
    font-weight: 500;
  } @else if $size == h3 {
    font-size: 2rem;
    font-weight: 500;
  } @else if $size == h4 {
    font-size: 1.5rem;
    font-weight: 500;
  } @else if $size == h5 {
    font-size: 1.25rem;
    font-weight: 500;
  } @else if $size == h6 {
    font-size: 1rem;
    font-weight: 500;
  }
}

@mixin text($size, $weight) {
  font-family: Arial, "Noto Sans TC", sans-serif;
  font-style: normal;
  line-height: 180%;
  letter-spacing: 0;

  cursor: default;

  @if $size == large {
    font-size: 1.25rem;
  } @else if $size == medium {
    font-size: 1.125rem;
  } @else if $size == normal {
    font-size: 1rem;
  } @else if $size == small {
    font-size: 0.875rem;
    line-height: 150%;
  } @else if $size == extra-small {
    font-size: 0.75rem;
    line-height: 150%;
  }

  @if $weight == bold {
    font-weight: 700;
  } @else if $weight == "" {
    font-weight: 400;
  } @else {
    font-weight: $weight;
  }
}

@mixin interation($element) {
  @if $element == btn {
    &:hover {
      color: $color__font--light;
      background: $color--hover;
    }
    &:focus,
    &:active {
      color: $color__font--light;
      background: $color--active;
      box-shadow: none;
    }
  } @else if $element == btn--dark {
    &:hover {
      color: $color--primary;
      background: $color--light;
    }
    &:focus,
    &:active {
      color: $color--primary;
      background: $color--light;
      box-shadow: none;
    }
  } @else if $element == btn--opacity {
    &:hover {
      background: $color--plain;
    }
    &:focus,
    &:active {
      .e-icon,
      div {
        color: $color__font--light;
      }
      background: $color__opacity--primary;
      box-shadow: none;
    }
  } @else if $element == link {
    &:hover {
      color: $color--hover;
    }
    &:focus,
    &:active {
      color: $color--active;
    }
  }
}

@mixin clear($element) {
  @if $element == btn {
    background: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    &:focus,
    &:checked {
      box-shadow: none;
      outline: none;
    }
  } @else if $element == input {
    background: transparent;
    box-shadow: none;
    outline: none;
    &:focus,
    &:checked {
      box-shadow: none;
      outline: none;
    }
  }
}

@mixin pesudo($content) {
  content: $content;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  font-size: 1rem;

  position: absolute;
  @if $content != "" {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else {
    top: 0;
    left: 0;
  }
}

@mixin square($size, $radius) {
  width: $size;
  height: $size;

  border-radius: $radius;
}

@mixin text-truncate($line) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin scrollbar($style) {
  @if $style == hidden {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  } @else {
    &::-webkit-scrollbar {
      width: 0.25rem;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 10rem;
    }

    &::-webkit-scrollbar-thumb {
      background: $color--primary;
      border-radius: 10rem;
      background-clip: content-box;
      transition: $transition;
      &:hover {
        background: $color--hover;
      }
    }
  }
}

@mixin arrow() {
  @include pesudo($icon__right);
  width: max-content;
  height: max-content;

  background-image: none;

  color: $color--primary;

  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin cursor($cursor) {
  cursor: $cursor;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  div,
  a,
  span,
  p,
  div,
  ul,
  li,
  label,
  input {
    cursor: $cursor;
  }
}

.u {
  &-scrollbar {
    @include scrollbar("");
  }
}
