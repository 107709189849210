.l-download-list {
  &__link {
    @include square(100%, 0);
    @include cursor(pointer);
  }
  &__item {
    width: 100%;
    aspect-ratio: 3 / 2;

    background: $color__bg--primary;
    border-radius: $radius__block;

    @include text(normal, "");
    color: $color--primary;

    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: $color--gray6;
    }
    &:focus,
    &:active {
      box-shadow: $shadow__focus;
    }
  }
}
