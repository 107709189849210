.c-content {
  $_: &;
  &__aside {
    width: 100%;
    height: 100%;
    padding: $padding__block;

    background: $color__bg--primary;
    border-radius: $radius__block;
  }
  &__map {
    width: 100%;
    height: auto;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__heading {
    @extend #{$_}__aside;

    margin-bottom: 1.5rem;
    &--sticky {
      margin-bottom: 1.5rem;

      box-shadow: $shadow__dropdown;

      position: sticky;
      top: $height__header;
      z-index: 1001;
      @media screen and (max-width: $lg) {
        position: static;
      }
    }
  }
  &__main {
    margin-top: $padding__section;
  }
  &__title {
    margin-right: 0.5rem;

    @include text(normal, 500);
    color: $color__font--secondary;
  }
  &__text {
    @include text(normal, 400);
    color: $color__font--subtext;
  }
}

.l-physical {
  $_: &;
  $color__bg--tag: rgba(255, 255, 255, 0.75);
  &__graph {
    margin-bottom: $padding__section;
    &:first-of-type {
      margin-top: $padding__section;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__wrapper {
    height: 100%;
    position: relative;
  }
  &__img {
    width: 100%;
    height: 100%;

    object-fit: contain;
  }
  &__site {
    padding: 0.5rem 1rem 0.5rem 0.5rem;

    position: absolute;
    z-index: 1;

    @include clear(btn);

    display: flex;
    align-items: center;

    @include cursor(pointer);
    &[data-site="泰平"] {
      top: 8%;
      left: 85%;
    }
    &[data-site="四堵"] {
      top: 28.5%;
      left: 72.5%;
    }
    &[data-site="坪林"] {
      top: 16%;
      left: 66%;
    }
    &[data-site="福山"] {
      top: 60.5%;
      left: 29.5%;
    }
    &[data-site="桶後"] {
      top: 40.5%;
      left: 46.5%;
    }
    &[data-site="屈尺"] {
      top: 20.5%;
      left: 36.5%;
    }
    &[data-site="福山植物園"] {
      top: 70%;
      left: 43%;
    }
    &::after {
      // 點位圓角矩形底
      @include pesudo("");
      width: 100%;
      height: 100%;

      border-radius: $radius--infinity;
      background: $color__bg--tag;
      box-shadow: $shadow__tag;

      z-index: -1;
    }
    &:hover {
      &::after {
        background: $color--secondary;
      }
      #{$_}__deco {
        background: $color--light;
      }
      #{$_}__icon {
        color: $color--secondary;
      }
      #{$_}__name {
        color: $color--light;
      }
    }
    &.active {
      &::after {
        background: $color--primary;
        box-shadow: $shadow__tag;
      }
      #{$_}__deco {
        background: $color--light;
      }
      #{$_}__icon {
        color: $color--primary;
      }
    }
  }
  // icon圓底
  &__deco {
    $size: 2rem;
    width: $size;
    min-width: $size;
    height: $size;
    min-height: $size;

    background: $color--primary;
    border-radius: 50%;

    position: relative;
  }
  // 圓形icon
  &__icon {
    color: $color--light;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__name {
    margin-left: 0.5rem;

    @include text(small, "");
    white-space: nowrap;
    &.active {
      font-weight: 500;
      color: $color__font--light;
    }
  }
  .echarts-for-react {
    height: max-content !important;
  }
  &__title {
    margin-bottom: 1rem;
    @include text(medium, 500);
    color: $color--primary;
  }
  &__select {
    margin-bottom: 1rem;
  }
  &__subtitle {
    width: 50%;
    max-width: 15rem;
    aspect-ratio: 1 / 1;
    margin: 0 0 0.5rem 0;

    border-radius: 50%;
    background: $color--primary;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $md) {
      width: 40%;
      margin: 0 0.5rem 0 0;
    }
    @media screen and (min-width: $md) and (max-width: $lg) {
      width: 20%;
      margin: 0 0.5rem 0 0;
    }
  }
  &__text {
    @include heading(h5);
    color: $color__font--light;
    @media screen and (max-width: $md) {
      @include heading(h6);
    }
  }
  &__logo {
    width: 45%;
    aspect-ratio: 1 / 1;
    @media screen and (max-width: $lg) {
      width: 30%;
      margin: 0;
    }
  }
  &__strong {
    @include heading(h3);
    font-weight: 900;
    color: $color--primary;
    @media screen and (max-width: $md) {
      @include text(medium, 800);
    }
  }
  &__subtext {
    margin-bottom: 0.25rem;

    @include text(normal, 400);
    color: $color__font--subtext;
    line-height: 150%;
  }
  &__hint {
    @include text(extra-small, 400);
    color: $color--error;

    display: block;
  }
  &__heading {
    margin-bottom: 1rem;
  }
  &__list {
    height: calc(100vh - 5rem - 16rem);
    padding: 0.25rem;

    overflow-x: hidden;
    overflow-y: auto;
    @include scrollbar("");
  }
  &__popover {
    @media screen and (max-width: $sm) {
      max-width: 13rem !important;
      min-width: 13rem !important;
    }
  }
}

.l-weather {
  $_: ".c-popover";
  .c-popover {
    .leaflet-popup-content {
      width: 25rem !important;
      @media screen and (max-width: $md) {
        width: 10rem !important;
      }
    }
    #{$_}__item {
      &[data-lang="en"] {
        grid-template-columns: 12rem 1fr;
      }
      &[data-lang="zh-tw"] {
        grid-template-columns: 8rem 1fr;
      }
    }
  }
}

.l-water-level {
  .leaflet-popup-content {
    @media screen and (max-width: $md) {
      width: 10rem !important;
      margin: 13px 24px 13px 20px;
    }
  }
}

.l-real-time-data {
  $height__graph: 25rem;

  .echarts-for-react {
    height: $height__graph !important;
  }
  .c-placeholder {
    height: $height__graph;

    position: relative;
  }
  &__graph {
    width: 100%;
    height: $height__graph + $height__subtitle + $margin__subtitle;
    margin-bottom: 2.5rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.l-chemical-data {
  &__list {
    margin-bottom: 1rem;
  }
}

.l-social-ecology {
  &__interviews {
    padding-top: 2rem;
  }
  &__block {
    margin-bottom: $margin__block;
    border: 0.125rem solid $color__bg--primary;
    border-radius: $radius__block;
  }
  &__heading {
    padding: $padding__block;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__hr {
    background: $color--primary;
  }
  &__tag {
    padding: $padding__tag;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;

    color: $color__font--primary;
    background: $color__bg--primary;
    border-radius: $radius__tag;
    &:last-of-type {
      margin-right: 0;
    }
  }
  &__text {
    @include text(small, 400);
    color: $color__font--muted;
    white-space: nowrap;
  }
  &__content {
    padding: $padding__block;

    background: $color--light;
    border-radius: $radius__block;

    white-space: pre-line;
  }
}

.l-population {
  .echarts-for-react {
    height: 600px !important;
  }
}

.u-graph {
  @media screen and (max-width: $md) {
    overflow-x: auto;
    .echarts-for-react {
      @media screen and (max-width: $md) {
        width: 50rem;
        height: auto !important;
      }
    }
  }
}
